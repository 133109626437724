module.exports = function( $ ) {

	var $instagramWrapper = $( this ).find( '.elementor-instagram' );
    var $carousel = $( this ).find( '.elementor-instagram-carousel' );

	if ( ! $instagramWrapper.length ) {
		return;
	}

	var options = $instagramWrapper.data( 'options' );
    var is_tag = false;
    var limit = 12;
    var username_or_tag = '';


	if(options.type == 'user'){
        if (options.user == '' ) {
            return;
        }
        limit = options.limit_user;
        username_or_tag = options.user;
    } else{
        if (options.tag == '' ) {
            return;
        }
        is_tag = true;
        limit = options.limit_tag;
        username_or_tag = options.tag;
    }

    $.instagramFeed({
        'username_or_tag': username_or_tag,
        'container': $instagramWrapper,
        'image_class': options.class,
        'styling': true,
        'is_tag':  is_tag,
        'items': limit,
        'image_size': options.image_size,
        'callback': function(data){
            if ( ! $carousel.length ) {
                return;
            }

            var savedOptions = $carousel.data( 'slider_options' ),
                defaultOptions = {
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: savedOptions.slidesToShowTablet,
                                slidesToScroll: savedOptions.slidesToShowTablet
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: savedOptions.slidesToShowMobile,
                                slidesToScroll: savedOptions.slidesToShowMobile
                            }
                        }
                    ]
                },

                slickOptions = $.extend( {}, defaultOptions, $carousel.data( 'slider_options' ) );

            $carousel.slick( slickOptions );
    }
});



};
